<template>
    <!-- 分隔线组件 -->
    <div :class="'liefeng-SeparationLine-view'" :style="'height:' + (data.height || height) * proportion + 'px;width:' + (data.width || width) * proportion + 'px'" v-if="!isParamComp">
        <img src="/images/line.png" />
    </div>
    <!-- 分隔线组件 -->
    <div class="liefeng-SeparationLine-params" v-else>
        <Form :label-width="150" ref="lineForm">
            <FormItem label="分隔线背景">
                <ColorPicker v-model="data.backgroundColor" recommend @on-active-change="colorChange" />
            </FormItem>
            <FormItem label="宽度">
                <Input type="Number" v-model.number="data.width" @on-blur="handleData"></Input>
            </FormItem>
            <FormItem label="分隔线高度">
                <Input type="Number" v-model.trim="data.height" @on-blur="handleData"></Input>
            </FormItem>
            <FormItem label="左边距">
                <Input type="Number" v-model.trim="data.marginLeft" @on-blur="handleData"></Input>
            </FormItem>
            <FormItem label="右边距">
                <Input type="Number" v-model.trim="data.marginRight" @on-blur="handleData"></Input>
            </FormItem>
            <FormItem label="上边距">
                <Input type="Number" v-model.trim="data.marginTop" @on-blur="handleData"></Input>
            </FormItem>
            <FormItem label="下边距">
                <Input type="Number" v-model.trim="data.marginBottom" @on-blur="handleData"></Input>
            </FormItem>
        </Form>
    </div>
</template>

<script>
/**
 * import SeparationLine from "./components/SeparationLine";
 * 分隔线
 */
export default {
    components: {},
    props: {
        isParamComp: { type: Boolean, default: false }, //true为参数设置组件,false为样式组件
        height: { type: Number, default: 100 }, //高度，单位：px
        width: { type: Number, default: 1920 }, //高度，单位：px
        data: {
            //组件数据，每个组件需要具备
            type: Object,
            default: () => {
                return {}
            },
        },
        target: {
            //组件对象，每个组件需要具备
            type: Object,
            default: () => {
                return {}
            },
        },
        proportion: {
            type: Number,
            default: () => {
                return 0
            },
        },
    },
    data() {
        return {
            initData: {
                //初始化用的数据，每个组件需要具备
                type: "SeparationLine",
                marginLeft: "0",
                marginRight: "0",
                marginTop: "0",
                marginBottom: "0",
                backgroundColor: "#f5f5f5",
                width: 1920,
                height: 100,
            },
        }
    },
    mounted() {
        if (this.isParamComp) this.handleData()
    },
    methods: {
        colorChange(color) {
            this.data.backgroundColor = color
            this.handleData()
        },
        handleData() {
            if (!this.data.width || this.data.width == "") {
                this.$Message.warning({
                    content: "宽度不能为0或者为空",
                    background: true,
                })
                return
            }
            if (!this.data.height || this.data.height == "") {
                this.$Message.warning({
                    content: "高度不能为0或者为空",
                    background: true,
                })
                return
            }
            var that = this.$(this.target)
            that.empty()
            that.css("width", "calc(" + this.data.width + 'px' + " - " + this.data.marginLeft * this.proportion + 'px' + " - " + this.data.marginRight * this.proportion + 'px' + ")")
            that.css("height", this.data.height * this.proportion)
            that.css("marginLeft", this.data.marginLeft * this.proportion)
            that.css("marginRight", this.data.marginRight * this.proportion)
            that.css("marginTop", this.data.marginTop * this.proportion)
            that.css("marginBottom", this.data.marginBottom * this.proportion)
            that.css("backgroundColor", this.data.backgroundColor)
        },
    },
}
</script>
<style lang="less">
.liefeng-SeparationLine-params {
    width: 100%;
    margin: 5px 0;
}
.liefeng-SeparationLine-view {
    width: 100%;
    margin: 5px 0;
    overflow: hidden;
    background-color: #eee;
    img {
        width: 100%;
    }
}
</style>